import { QueryClient, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { RecommendationData } from './recommendationDataTypes';

export const prefetchQuery = ['prefetchQuery'];
export const fetchMoreQuery = ['fetchMoreQuery'];

export function usePrefetchRecommendationDataQuery() {
    const { data: recommendationData, isFetched } =
        useQuery<RecommendationData>({
            queryKey: prefetchQuery,
        });
    return {
        recommendationData,
        isFetched,
    };
}
export async function prefetchRecommendationDataQuery(
    queryClient: QueryClient,
    prefetchQueryWithCookies: (signal: AbortSignal) => Promise<any>,
) {
    await queryClient.prefetchQuery<RecommendationData>({
        queryKey: prefetchQuery,
        queryFn: async ({ signal }) => {
            try {
                const data = await prefetchQueryWithCookies(signal);
                queryClient.setQueryData(prefetchQuery, data);
                return data;
            } catch (err) {
                console.warn(err);
                return null;
            }
        },
    });
}

export function useInfiniteScrollFetchMoreData(
    apiUrl: string,
    recommender: string,
    position: string,
    fetchMoreUrls: string[],
) {
    let hasMoreFetchData = true;
    const { data, fetchNextPage, isFetched } = useInfiniteQuery({
        queryKey: fetchMoreQuery,
        queryFn: async ({ meta, pageParam, signal }) => {
            if (!fetchMoreUrls || fetchMoreUrls.length == 0) {
                return null;
            }
            const { apiUrl } = meta ?? {};
            const url = new URL(`${apiUrl}/fetch-more`);
            const fetchMoreTarget = fetchMoreUrls[pageParam];

            const urlParams = new URLSearchParams({
                target: fetchMoreTarget,
                recommender,
                position,
                fetchMoreUrlsString: fetchMoreUrls?.join(','),
            });

            url.search = urlParams.toString();

            try {
                const response = await fetch(url, { signal });
                if (!response.ok) {
                    console.warn(
                        `Got status ${response.status} calling ${url}`,
                    );
                    return null;
                }
                return response.json();
            } catch (err) {
                if (err.name === 'AbortError') {
                    console.warn('Fetch request aborted:', url);
                    return null;
                }
                console.warn(`Error calling ${url}`);
                return null;
            }
        },
        meta: { apiUrl, recommender, position },
        initialPageParam: 0,
        getNextPageParam: (_, _allPages, lastPageParam) => {
            if (Number(lastPageParam) < (fetchMoreUrls?.length ?? 0) - 1) {
                return Number(lastPageParam) + 1;
            }
            hasMoreFetchData = false;
            return null;
        },
    });
    return {
        fetchMoreData: data?.pages ? data : null,
        isPersonal: data?.pages?.[0]?.isPersonal ?? false,
        fetchNextPage,
        isFetched,
        hasMoreFetchData,
    };
}
